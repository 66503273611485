// Button: variant mixins

@mixin button-danger {
  color: var(--btn-text-color);
  background-color: var(--destructive-color);
  border-color: var(--destructive-color);

  &:hover,
  &:focus {
    color: var(--btn-text-color);
    background-color: var(--danger-hover);
    border-color: var(--danger-hover);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem var(--focus-btn-danger);
  }

  &:active {
    color: var(--btn-text-color);
    background-color: var(--danger-active);
    border-color: var(--danger-active);
  }
}

@mixin button-primary {
  color: var(--btn-text-color);
  background-color: var(--btn-primary-bg);
  border-color: var(--btn-primary-bg);

  &:hover,
  &:focus {
    color: var(--btn-text-color);
    background-color: var(--btn-primary-bg-hover);
    border-color: var(--btn-primary-bg-hover);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem var(--focus-btn-primary);
  }

  &:active {
    color: var(--btn-text-color);
    background-color: var(--btn-primary-bg-active);
    border-color: var(--btn-primary-bg-active);
  }
}

@mixin button-secondary {
  color: var(--btn-secondary-color);
  border-color: var(--btn-secondary-border);
  background-color: var(--btn-secondary-bg);

  &:focus {
    color: var(--btn-secondary-color--focus);
    border-color: var(--btn-secondary-border--focus);
    background-color: var(--btn-secondary-bg--focus);
    box-shadow: 0 0 0 0.2rem var(--focus-btn-secondary);
  }

  // Hover MUST be AFTER the focus declaration for this outlined button.
  // Otherwise, the hover styles will not be applied when hovering over a focused element
  &:hover {
    color: var(--btn-secondary-color--hover);
    border-color: var(--btn-secondary-border--hover);
    background-color: var(--btn-secondary-bg--hover);
  }

  &:active {
    color: var(--btn-secondary-color--active);
    border-color: var(--btn-secondary-border--active);
    background-color: var(--btn-secondary-bg--active);
  }
}

@mixin button-link {
  color: var(--accent-color);
  background-color: transparent;
  border-color: transparent;
  font-weight: var(--btn-link-font-weight);

  &:hover,
  &:focus {
    color: var(--btn-link-color--hover);
    background-color: var(--btn-link-bg--hover);
    border-color: var(--btn-link-bg--hover);
  }

  &:focus {
    box-shadow: none;
  }

  &:active {
    color: var(--btn-link-color--active);
    background-color: var(--btn-link-bg--active);
    border-color: var(--btn-link-bg--active);
  }
}

.button-link {
  @include button-link;
}

@mixin button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button-disabled {
  @include button-disabled;
}
